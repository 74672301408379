import {
	AlignmentType,
	Document,
	HeadingLevel,
	ImageRun,
	Paragraph,
	Table,
	TableCell,
	TableRow,
	TextRun,
	WidthType
} from "docx";
import moment from "moment";
import striptags from "striptags";
import convertSvgToPng64 from "./convertSvgToPng64";

const stripTagsAndSpecialCharts = (string) => {
	string = striptags(string);
	return string.replace("&nbsp;", "");
}

const prepareChildren = async (questionsSummaries, survey) => {
	const elements = [];

	elements.push(new Paragraph({
		text: "Wyniki ankiety: " + stripTagsAndSpecialCharts(survey.name),
		alignment: AlignmentType.CENTER,
	}))
	elements.push(new Paragraph({
		text: "Wyniki wygenerowane za pomocą Ankieteo.pl",
		alignment: AlignmentType.CENTER,
	}))
	elements.push(new Paragraph({
		text: "",
		heading: HeadingLevel.HEADING_1,
		alignment: AlignmentType.CENTER,
	}))
	elements.push(new Paragraph({
		text: moment().locale("pl").format("LLL"),
		alignment: AlignmentType.CENTER,
	}))
	elements.push(new Paragraph({
		text: "",
		heading: HeadingLevel.HEADING_1,
		alignment: AlignmentType.CENTER,
	}))

	for (const summary of questionsSummaries) {
		const i = questionsSummaries.indexOf(summary);
		const image = await convertSvgToPng64("#container-question-" + summary.question.id + " svg")
		elements.push(new Paragraph({
			text: summary.question.content ? stripTagsAndSpecialCharts(summary.question.content) : "Brak treści pytania",
			heading: HeadingLevel.TITLE,
			alignment: AlignmentType.CENTER,
			pageBreakBefore: i !== 0,
		}));
		elements.push(new Paragraph({
			text: "",
			heading: HeadingLevel.HEADING_1,
			alignment: AlignmentType.CENTER,
		}))
		elements.push(new Paragraph({
			text: "N = " + summary.data.n,
			heading: HeadingLevel.HEADING_1,
			alignment: AlignmentType.CENTER,
		}))
		elements.push(new Paragraph({
			text: "",
			heading: HeadingLevel.HEADING_1,
			alignment: AlignmentType.CENTER,
		}))
		if (image) {
			const imageWidth = 600;
			let percent = (100 * image.height) / image.width;
			let percentage = (imageWidth/100) * percent;
			elements.push(new Paragraph({
				children: [
					new ImageRun({
						data: image.data,
						transformation: {
							width: 600,
							height: percentage,
						}
					}),
				],
			}));
		}
		elements.push(new Paragraph({
			text: "",
			heading: HeadingLevel.HEADING_1,
			alignment: AlignmentType.CENTER,
		}))

		const tableElement = document.querySelector("#container-question-" + summary.question.id + " table");
		if (!tableElement) {
			continue;
		}

		elements.push(createTableByType(summary))
	}

	elements.push(new Paragraph({
		text: "",
		heading: HeadingLevel.HEADING_1,
		alignment: AlignmentType.CENTER,
	}))
	elements.push(new Paragraph({
		text: "Wyniki wygenerowane za pomocą Ankieteo.pl",
		alignment: AlignmentType.CENTER,
	}))

	return elements;
}

const createTableByType = (summary) => {
	if (summary.data.n === 0) {
		return new Paragraph({
			text: "Brak danych",
			heading: HeadingLevel.HEADING_1,
			alignment: AlignmentType.CENTER,
		})
	}

	const table = new Table({
		columnWidths: [4505, 4505],
		rows: getColumnsRows(summary)
	});

	return table;
}

const getTableRowStyles = () => {
	return {
		value: 300
	};
}

const getTableCell = (text, bold = false) => {
	return new TableCell({
		width: {
			size: 4505,
			type: WidthType.DXA,
		},
		children: [new Paragraph({
			spacing: {
				before: 50,
			},
			children: [
				new TextRun({
					text: "  " + stripTagsAndSpecialCharts(text),
					bold: bold,
					size: 16,
				}),
			]
		})],
	})
}

const getColumnsText = (element) => {
	let stringToReturn = "";
	let number = 1;
	Object.entries(element).forEach(entry => {
		const [key, value] = entry;
		if (key.includes('column')) {
			stringToReturn = stringToReturn + "Kolumna" + " " + number.toString() + " Wartość " + value.toString() + " ";
			number++;
		}

		if (key.includes("lat")) {
			stringToReturn = stringToReturn + "Lat: " + value.toString() + " "
		}

		if (key.includes("lng")) {
			stringToReturn = stringToReturn + "Lng: " + value.toString() + " "
		}

		if (key.includes("value")) {
			stringToReturn = stringToReturn + "Wartość: " + number.toString() + " " + value.toString() + " "
			number++;
		}
	});

	return stringToReturn;
}

const getAnswerText = (element) => {
	if (element.x) {
		return "Średnia"
	}

	if (element.lat) {
		return "Położenie"
	}

	if (element.row) {
		return stripTagsAndSpecialCharts(element.row);
	}

	return "Do zmiany"
}

const getColumnsRows = (summary) => {
	const rows = [];

	if (summary.data.data && !summary.data.data.length && summary.data.data.nps) {
		rows.push(new TableRow({
			height: getTableRowStyles(),
			children: [
				getTableCell("Krytycy: " + summary.data.data.data.critics),
				getTableCell("Neutralni: " + summary.data.data.data.neutrals),
				getTableCell("Promotorzy: " + summary.data.data.data.promotors),
			],
		}))
	} else if (summary.data.data && !summary.data.data.watched  && !summary.data.data.seen) {
		summary.data.data.forEach((element) => {
			if (element.leftContent) {
				rows.push(new TableRow({
					height: getTableRowStyles(),
					children: [
						getTableCell(stripTagsAndSpecialCharts(element.leftContent) + " - " + stripTagsAndSpecialCharts(element.rightContent)),
						getTableCell(element.avg.toString()),
					],
				}))
			} else {
				rows.push(new TableRow({
					height: getTableRowStyles(),
					children: [
						getTableCell(element.content ? stripTagsAndSpecialCharts(element.content.toString()) : getAnswerText(element)),
						getTableCell(element.value ? stripTagsAndSpecialCharts(element.value.toString()) : getColumnsText(element)),
					],
				}))
			}
		});
	} else {

		rows.push(new TableRow({
			height: getTableRowStyles(),
			children: [
				getTableCell("Odpowiedź", true),
				getTableCell("Liczba wskazań", true)
			],
		}))
	}

	if (summary.data.answers) {
		summary.data.answers.forEach((answer) => {
			if (answer.place_1 || answer.place_2 || answer.place_3) {
				rows.push(new TableRow({
					height: getTableRowStyles(),
					children: [
						getTableCell(stripTagsAndSpecialCharts(answer.content)),
						getTableCell("Miejsce 1: " + answer.place_1 ? answer.place_1.toString() : "0" + " \n" + "Miejsce 2: " + answer.place_2 ? answer.place_2.toString() : "0" + " \n"  + "Miejsce 2: " + answer.place_3 ? answer.place_3.toString() : "0" + " \n")
					],
				}))
			} else {
				rows.push(new TableRow({
					height: getTableRowStyles(),
					children: [
						getTableCell(stripTagsAndSpecialCharts(answer.content)),
						getTableCell(answer.value.toString()),
					],
				}))
			}
		})
	} else if (summary.data.data) {
		if (summary.data.data.watched) {
			rows.push(new TableRow({
				height: getTableRowStyles(),
				children: [
					getTableCell(stripTagsAndSpecialCharts("Obejrzane do końca")),
					getTableCell(stripTagsAndSpecialCharts(summary.data.data.watched))
				],
			}));
			rows.push(new TableRow({
				height: getTableRowStyles(),
				children: [
					getTableCell(stripTagsAndSpecialCharts("Nieobejrzane do końca")),
					getTableCell(stripTagsAndSpecialCharts(summary.data.data.notWatched))
				],
			}))
		}

		if (summary.data.data.seen || summary.data.data.notSeen) {
			rows.push(new TableRow({
				height: getTableRowStyles(),
				children: [
					getTableCell(stripTagsAndSpecialCharts("Obejrzane")),
					getTableCell(stripTagsAndSpecialCharts(summary.data.data.seen.toString()))
				],
			}));
			rows.push(new TableRow({
				height: getTableRowStyles(),
				children: [
					getTableCell(stripTagsAndSpecialCharts("Nieobejrzane")),
					getTableCell(stripTagsAndSpecialCharts(summary.data.data.notSeen.toString()))
				],
			}))
		}
	}

	return rows;
}

const questionsSummaryToDocxConverter = async (questionsSummaries, survey) => {
	const document = new Document({
		sections: [
			{
				children: await prepareChildren(questionsSummaries, survey)
			}
		]
	});

	return document;
}

export default questionsSummaryToDocxConverter;