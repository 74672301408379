import pptxgen from "pptxgenjs";
import striptags from 'striptags';
import moment from "moment";
import convertSvgToBase64 from "./convertSvgToBase64";

const stripTagsAndSpecialCharts = (string) => {
	string = striptags(string);
	return string.replace("&nbsp;", "");
}

const createTableFromSummary = (summary, slide, pptx = null) => {
	let rows = [];
	const tableElement = document.querySelector("#container-question-" + summary.question.id + " table");
	if (!tableElement) {
		return;
	}

	if (pptx) {
		slide = createSlideWithHeader(pptx, summary)
	}

	if (summary.data.answers) {
		rows.push(["Odpowiedź", "Liczba wskazań"])
		if (summary.question.type === 'ranked') {
			summary.data.answers.forEach((answer) => {
				let rankedString = ""
				for (let place = 1; place <= summary.question.places; place++) {
					rankedString += "Miejsce " + place + " " + answer['place_1'] + "/n"
				}
				rows.push([stripTagsAndSpecialCharts(answer.content), rankedString]);
			});
		} else {
			if (summary.data.answers) {
				summary.data.answers.forEach((answer) => {
					rows.push([stripTagsAndSpecialCharts(answer.content), answer.value]);
				});
			}
		}

	} else {
		if (summary.question.type === 'maxDiff') {
			rows.push(["Lewa stron", "Odpowiedź", "Prawa strona"])
			summary.data.data.forEach((answer) => {
				rows.push([answer.value_1, stripTagsAndSpecialCharts(answer.content), answer.value_2]);
			});
		}

		if (summary.question.type === 'video') {
			rows.push(["Odpowiedź", "Liczba wskazań"])
			rows.push(["Wyświetlono", summary.data.data.watched])
			rows.push(["Nie wyświetlono", summary.data.data.notWatched])
		}

		if (summary.question.type === 'comment') {
			rows.push(["Odpowiedź", "Liczba wskazań"])
			rows.push(["Obejrzane do końca", summary.data.data.seen])
			rows.push(["Nieobejrzane do końca", summary.data.data.notSeen])
		}
		if (summary.question.type === 'differential') {
			rows.push(["Wiersz", "Średnia"])
			summary.data.data.forEach((answer) => {
				rows.push([stripTagsAndSpecialCharts(answer.leftContent) + " - " + stripTagsAndSpecialCharts(answer.rightContent), answer.avg])
			});
		}
	}

	if (rows) {
		slide.addTable(rows, {
			x: 1,
			y: 1.8,
			w: 8,
			rowH: 0.3,
			border: {
				type: "solid",
				pt: 1
			}
		});
	}
}

const createSlideWithHeader = (pptx, summary) => {
	const slide = pptx.addSlide();
	slide.addText(
		summary.question.content ? stripTagsAndSpecialCharts(summary.question.content) : "Brak treści pytania",
		{ x:1, y:0.2, w:'80%', h:1, fontSize:24, align:'center' }
	);
	slide.addText(
		"N = " + summary.data.n,
		{ x:1, y:1, w:'80%', h:1, fontSize:18, align:'center' }
	);

	return slide;
}

const createQuestionSlide = (pptx, summary) => {
	const chartSlide = createSlideWithHeader(pptx, summary)
	let createTableSlide = true;
	if (summary.data.n === 0) {
		chartSlide.addText(
			"Brak danych",
			{ x:1, y:3, w:'80%', h:1, fontSize:30, align:'center' }
		);
	} else {
		const svg = convertSvgToBase64("#container-question-" + summary.question.id + " svg");
		if (svg) {
			const imageWidth = 10;
			let percent = (100 * svg.height) / svg.width;
			let percentage = (imageWidth/100) * percent;
			chartSlide.addImage({
				data: svg.data,
				x: 0,
				y: 1.8,
				w: imageWidth,
				h: percentage
			});
		} else {
			createTableSlide = false;
			createTableFromSummary(summary, chartSlide)
		}
	}

	if (createTableSlide) {
		createTableFromSummary(summary, null, pptx)
	}


}

const questionsSummaryToPptxConverter = (questionsSummaries, survey) => {
	const pres = new pptxgen();

	pres.author = 'Ankieteo.pl';
	pres.subject = striptags(survey.name);
	pres.title = 'Wyniki ankiety';

	const titleSlide = pres.addSlide();

	titleSlide.addText(
		"Wyniki ankiety: "+ striptags(survey.name),
		{ x:1, y:0.2, w:'80%', h:1, fontSize:24, align:'center' }
	);

	titleSlide.addText(
		"Wyniki wygenerowane za pomocą Ankieteo.pl",
		{ x:1, y:1.5, w:'80%', h:1, fontSize:20, align:'center' }
	);

	titleSlide.addText(
		moment().locale("pl").format("LLL"),
		{ x:1, y:3, w:'80%', h:1, fontSize:18, align:'center' }
	);

	questionsSummaries.forEach((summary) => {
		createQuestionSlide(pres, summary)
	})

	return pres;
}

export default questionsSummaryToPptxConverter;